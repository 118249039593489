import { ChakraProvider, FormControl, FormLabel, Input, Select, Textarea, useDisclosure } from "@chakra-ui/react"
import CompanyAutoComplete from "components/companyAutoComplete/CompanyAutoComplete"
import { getMonth, months } from "helpers/months"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useAppSelector } from "redux/hooks"
import api from "services/api"
import { ClientType, ContractType, SelectType, UserType } from "types/app"
import CurrencyInput from "./components/CurrencyInput"
import { toastError } from "components/toasts/toastError"
import { ErrorData } from "types/fetch"
import { useNavigate, useParams } from "react-router-dom"
import { stringToFloat, stringToInteger } from "helpers/conversions"
import Dropdown from "components/dropdown"
import Calendar from "react-calendar"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import { Select as SelectWithText } from 'chakra-react-select'
import dayjs from "dayjs"
import { tiposContratos } from "variables/contratos"

interface DataType {
    id?: number
    description?: string
    active?: boolean
    note?: string
	descricaoNota?: string,
    value?: number
    birthday?: number
    paymentMethod?: number
    clientId?: number
    createdById?: number
    ownerId?: number
    createdAt?: Date
    startDate?: Date
    nocSendDate?: Date
    billingDay?: number,
	preVendasId?: number,
	dataAssinatura?: string,
	tipoContratoId?: number,
	dataCancelamento?: string
}

const ContractsEdit = () => {

    const { id } = useParams()

    const user = useAppSelector(selector => selector.user.user)
    const darkmode = useAppSelector(selector => selector.layout.darkMode)
    const [data, setData] = useState<DataType>({
    })
    const [companyNameTimeout, setCompanyNameTimeout] = useState<any>()
    const [companyName, setCompanyName] = useState('')
    const [loadingCompanys, setLoadingCompanys] = useState(false)
    const [companySelect, setCompanySelect] = useState<SelectType[]>([]);

    const [users, setUsers] = useState<UserType[]>([])

    const { isOpen, onOpen, onClose } = useDisclosure()

    const navigate = useNavigate()

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if(!data.clientId){
            toast("Selecione um cliente", {
                theme: darkmode ? "dark" : "light",
                type: "error"
            })
            return;
        }
        if(!data.value){
            toast("Digite um valor para o contrato", {
                theme: darkmode ? "dark" : "light",
                type: "error"
            })
            return;
        }

        if(!data.billingDay || data.billingDay == -1){
            toast("Selecione uma data de faturamento", {
                theme: darkmode ? "dark" : "light",
                type: "error"
            })
            return;
        }

        api.put("/contracts/edit/"+id, {
            description: data.description,
            active: data.active,
            note: data.note,
            value: stringToFloat(data.value.toString().replace(/[a-zA-Z]\$|\./gi, '').replace(/\,/gi, '.')),
            birthday: stringToInteger(data.birthday),
            paymentMethod: stringToInteger(data.paymentMethod),
            clientId: stringToInteger(data.clientId),
            createdById: data.createdById,
            ownerId: stringToInteger(data.ownerId),
            createdAt: data.createdAt,
            startDate: data.startDate,
            nocSendDate: data.nocSendDate,
            billingDay: data.billingDay,
			preVendasId: data.preVendasId,
			descricaoNota: data.descricaoNota,
			dataAssinatura: data.dataAssinatura,
			dataCancelamento: data.dataCancelamento,
			tipoContratoId: stringToInteger(data.tipoContratoId)
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            navigate('/admin/contracts/list')
            toast("Contrato salvo com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
        }).catch((err: ErrorData)=>{
            toastError(err, darkmode)
        })
    }

    const handleChangeInput = (key: keyof (DataType), val: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [key]: val.target.value })
    }
    const handleChangeValue = (key: keyof (DataType), val: any) => {
        setData({ ...data, [key]: val })
    }


    const getClients = (_search?: string) => {
        setLoadingCompanys(true)
        api.get("/clients/all/autocomplete", {
            headers: {
                token: user?.token
            },
            params: {
                search: _search ? _search : ""
            }
        }).then((res: { data: ClientType[] }) => {
            setLoadingCompanys(false)
            var _newClients: SelectType[] = []
            res.data.map(client => {
                _newClients.push({
                    content: client.name ? client.name : "Sem nome",
                    value: client.id.toString()
                })
            })
            setCompanySelect(_newClients)
        }).catch((error) => {
            setLoadingCompanys(false)
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const handleSelectClient = (newClient: string) => {
        const _companyId = isNaN(parseInt(newClient)) ? '-1' : newClient
        handleChangeValue("clientId", _companyId)
    }

    const handleChangeClient = (companyName: string) => {
        setCompanyName(companyName)
        if (companyNameTimeout) {
            clearInterval(companyNameTimeout)
        }
        setCompanyNameTimeout(setTimeout(() => {
            getClients(companyName)
            setCompanyNameTimeout(null)
        }, 500))
    }

    const getUsers = () => {
        api.get('/users/select', {
            headers: {
                token: user?.token
            }
        }).then((res: {data: UserType[]}) => {
            setUsers(res.data)
        }).catch((error: ErrorData)=> {
            toastError(error, darkmode)
        })
    }

    const getContract = () => {
        api.get('contracts/show/'+id, {
            headers: {
                token: user?.token
            }
        }).then((res: {data: ContractType})=> {
            const newContract = res.data as any
            newContract.createdAt = dayjs(newContract.createdAt).toDate()
            newContract.startDate = dayjs(newContract.startDate).toDate()
            newContract.nocSendDate = dayjs(newContract.nocSendDate).toDate()
            if(newContract.value){
                newContract.value = Intl.NumberFormat('pt-BR', {style: "currency", currency: "BRL"}).format(newContract.value.toString());
            }
            setData(newContract as DataType)
            setCompanyName(res.data.client.name)
        }).catch(err=> {
            toastError(err, darkmode)
        })
    }

    useEffect(()=> {
        getClients()
        getUsers()
        getContract()
    }, [])

    return(
        <>
            <form onSubmit={handleSubmit} className="grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 mt-10 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">
                <FormControl className="w-full flex gap-1 flex-col col-span-2">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Cliente</FormLabel>
					<CompanyAutoComplete
						loading={loadingCompanys}
						extra=""
						className="md:col-span-1 2xl:col-span-4"
						inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2"
						label=""
						variant="auth"
						placeholder=""
						id="orderClient"
						onChange={handleChangeClient}
						onSelect={handleSelectClient}
						value={companyName}
						options={companySelect}
					/>
                </FormControl>

				<div className="grid grid-cols-5 col-span-4 gap-5">
					<FormControl className="w-full flex gap-1 flex-col">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Data de criação</FormLabel>
						<Dropdown
							button={
							<button
								type="button"
								onClick={() => onOpen()}
								className={`no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2`}
							>
								{data.createdAt ? dayjs(data.createdAt).format("DD/MM/YYYY") : "Clique para definir"}
							</button>
							}
							extraClassesWrapper="w-full h-10"
							extraClassesButton="w-full h-10"
							animation={"origin-top-right transition-all duration-300 ease-in-out"}
							classNames={`top-11 right-0 w-max `}
							children={
							<div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
								<Calendar
									value={data.createdAt}
									onClickDay={e=> handleChangeValue("createdAt", e)}
									prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
									nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
									view={"month"}
								/>
							</div>
							}
						/>
					</FormControl>

					<FormControl className="w-full flex gap-1 flex-col">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Data de assinatura</FormLabel>
						<Dropdown
							button={
								<button
									type="button"
									onClick={() => onOpen()}
									className={`no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2`}
								>
									{data.dataAssinatura ? dayjs(data.dataAssinatura).format("DD/MM/YYYY") : "Clique para definir"}
								</button>
							}
							extraClassesWrapper="w-full h-10"
							extraClassesButton="w-full h-10"
							animation={"origin-top-right transition-all duration-300 ease-in-out"}
							classNames={`top-11 right-0 w-max `}
							children={
							<div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
								<Calendar
									value={data.dataAssinatura ? dayjs(data.dataAssinatura).toDate() : undefined}
									onClickDay={e=> handleChangeValue("dataAssinatura", e)}
									prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
									nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
									view={"month"}
								/>
							</div>
							}
						/>
					</FormControl>

					<FormControl className="w-full flex gap-1 flex-col">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Data de início</FormLabel>
						<Dropdown
							button={
							<button
								type="button"
								onClick={() => onOpen()}
								className={`no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2`}
							>
								{data.startDate ? dayjs(data.startDate).format("DD/MM/YYYY") : "Clique para definir"}
							</button>
							}
							extraClassesWrapper="w-full h-10"
							extraClassesButton="w-full h-10"
							animation={"origin-top-right transition-all duration-300 ease-in-out"}
							classNames={`top-11 right-0 w-max `}
							children={
							<div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
								<Calendar
									value={data.startDate}
									onClickDay={e=> handleChangeValue("startDate", e)}
									prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
									nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
									view={"month"}
								/>
							</div>
							}
						/>
					</FormControl>

					<FormControl className="w-full flex gap-1 flex-col">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Dia de Faturamento</FormLabel>
						<Select value={data.billingDay} icon={<></>} onChange={e=> handleChangeValue("billingDay", e.target.value)} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
							<option value={-1}>Nenhum</option>
							{[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28].map(val=>(
								<option key={"DAY-"+val} value={val}>{val}</option>
							))}
						</Select>
					</FormControl>

					<FormControl className="w-full flex gap-1 flex-col">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Data de cancelamento</FormLabel>
						<Dropdown
							button={
								<button
									disabled={data.active}
									type="button"
									onClick={() => onOpen()}
									className={`no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2`}
								>
									{!data.active ? data.dataCancelamento ? dayjs(data.dataCancelamento).format("DD/MM/YYYY") : "Clique para definir" : "Contrato ativo"}
								</button>
							}
							extraClassesWrapper="w-full h-10"
							extraClassesButton="w-full h-10"
							animation={"origin-top-right transition-all duration-300 ease-in-out"}
							classNames={`top-11 right-0 w-max `}
							children={
							<div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
								<Calendar
									value={dayjs(data.dataCancelamento).isValid() ? dayjs(data.dataCancelamento).toDate() : null}
									onClickDay={e=> handleChangeValue("dataCancelamento", e)}
									prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
									nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
									view={"month"}
								/>
							</div>
							}
						/>
					</FormControl>
				</div>

				<FormControl className="w-full flex gap-1 flex-col">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Valor do contrato</FormLabel>
					<CurrencyInput value={data.value} onChange={e=> handleChangeValue("value", e.target.value)} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="R$0,00" />
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col col-span-1">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Vendedor responsável</FormLabel>
					<ChakraProvider>
						<SelectWithText options={users.map(user=>{
							let _user: any = {}
							_user.value = user.id
							_user.label = user.name
							return _user
						})} value={{value: data.ownerId, label: users.find(u=> u.id == data.ownerId)?.name}} onChange={(e: any) => {
							handleChangeValue("ownerId", e.value)
						}} className="border border-gray-200 shadow-md shadow-600 rounded w-full" noOptionsMessage={() => "Sem dados"} placeholder={"Selecione um"} />
					</ChakraProvider>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col col-span-1">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Pré vendas</FormLabel>
					<ChakraProvider>
						<SelectWithText options={users.map(user=>{
							let _user: any = {}
							_user.value = user.id
							_user.label = user.name
							return _user
						})} value={{value: data.preVendasId, label: users.find(u=> u.id == data.preVendasId)?.name}} onChange={(e: any) => {
							handleChangeValue("preVendasId", e.value)
						}} className="border border-gray-200 shadow-md shadow-600 rounded w-full" noOptionsMessage={() => "Sem dados"} placeholder={"Selecione um"} />
					</ChakraProvider>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">O contrato está ativo?</FormLabel>
					<Select value={data.active ? 1 : 0} icon={<></>} onChange={e=> handleChangeValue("active", e.target.value == '0' ? false : true)} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
						<option value={0}>Não</option>
						<option value={1}>Sim</option>
					</Select>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Método de pagamento</FormLabel>
					<Select value={data.paymentMethod} icon={<></>} onChange={e=> handleChangeValue("paymentMethod", e.target.value)} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
						<option value={0}>Boleto</option>
						<option value={1}>TED</option>
					</Select>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Tipo de contrato</FormLabel>
					<Select value={data.tipoContratoId} icon={<></>} placeholder="Selecione um" onChange={e=> handleChangeValue("tipoContratoId", e.target.value)} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
						{tiposContratos.map(t=> (
							<option key={"TIPO-"+t.id} value={t.id}>{t.nome}</option>
						))}
					</Select>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col lg:col-span-2">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Descrição do contrato</FormLabel>
                    <Textarea minH={200} value={data.description} onChange={e=> handleChangeValue("description", e.target.value)} placeholder="Digite a descrição do contrato" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" />
                </FormControl>
				<FormControl className="w-full flex gap-1 flex-col lg:col-span-2">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Anotações</FormLabel>
                    <Textarea minH={200} value={data.note} onChange={e=> handleChangeValue("note", e.target.value)} placeholder="Campo para anotações referentes ao contrato" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" />
                </FormControl>
				<FormControl className="w-full flex gap-1 flex-col lg:col-span-2">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Descritivo para a nota fiscal</FormLabel>
                    <Textarea minH={200} value={data.descricaoNota} onChange={e=> handleChangeValue("descricaoNota", e.target.value)} placeholder="Campo para anotações para serem inseridas na NF do contrato" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" />
                </FormControl>

				<div className="col-span-6">
					<button type="submit" className="linear h-10 mt-5 ml-auto flex items-center justify-center rounded-xl bg-green-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
						Atualizar contrato
					</button>
				</div>
            </form>
        </>
    )
}

export default ContractsEdit